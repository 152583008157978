import React from "react"
import Layout from "../layout/layout"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import SEO from "../components/seo"

const VideoPage = ({ data }) => (
  <Layout>
    <SEO title="Latest Videos" />
    <div>
      <h1>Latest Video</h1>
      {data.allMarkdownRemark.edges.map(post => (
        <div key={post.node.id}>
          <h3>{post.node.frontmatter.title}</h3>
          <small>
            Posted by {post.node.frontmatter.author} on{" "}
            {post.node.frontmatter.date}
          </small>
          <br />
          <Link to={post.node.frontmatter.path}>Read More</Link>
          <br />
          <hr />
        </div>
      ))}
    </div>
  </Layout>
)

export const pageQuery = graphql`
  query VideoIndexQuery {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            author
            date
            path
            title
          }
        }
      }
    }
  }
`

export default VideoPage
